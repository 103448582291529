let winH = window.innerHeight;
const winW = window.innerWidth;
const bodyElement = document.body;
let PCWidth = window.matchMedia( '(min-width: 768px)' ).matches;

(function($){
	$('.innerLink[href^="#"]').click(function () {
		smooth(this);
		return false;
	});

	// $('.pageTop a').click(function () {
	// 	animateoffset(0);
	// 	return false;
	// });

	$('.pageTop a').on('click resize', function() {
		if (PCWidth) {
			animateoffset(0);
		} else {
			// sp時の処理追加
			if ($('.pageTop').hasClass('is-spTop')) {
				const $about = $('#about');
				const aboutPos = $about.offset().top;
				animateoffset(aboutPos);
			} else {
				animateoffset(0);
			}
		}
		return false;
	});

	//smoothScroll
	function smooth(target){
		var href = $(target).attr('href');//引数targetが持つhref値を取得
		//var offset = $('#pageHeader').height();
		var offset = 0;//何px下に下げるかの数値
		var top = $(href).offset().top - offset;
		animateoffset(top);//実際にスクロールさせる関数animateoffsetを実行
	}
	function animateoffset(top){
		$('html,body').animate({scrollTop:top},600);//引数topの値までスクロールさせる
	}
	//smoothScroll

	const pageTop = $('.pageTop');
	
	$(window).on('load resize', function() {
		if (PCWidth) {
			pageTop.hide();
		} else {
			pageTop.show();
		}
	});

	$(window).on('load resize scroll', function() {
		let scrollTop = $(this).scrollTop();
		const $mvSp = $('.js-mv');
		const mvSpHeight = $mvSp.outerHeight() - 50;
		const $pageTopText = $('.js-pageTopText');
		
		winH = window.innerHeight;
		PCWidth = window.matchMedia( '(min-width: 768px)' ).matches;

		if (PCWidth) { // pcの時
			if(scrollTop > winH){
				pageTop.fadeIn();
			}else {
				pageTop.fadeOut();
			}
		} else { // spの時
			if (scrollTop > 100) {
				pageTop.removeClass('is-spTop');
			} else {
				pageTop.addClass('is-spTop');
			}

			if(scrollTop > mvSpHeight){
				pageTop.addClass('is-spScrolling');
				$pageTopText.text('SCROLL TOP');
			} else {
				$pageTopText.text('SCROLL DOWN');
				pageTop.removeClass('is-spScrolling');
				pageTop.addClass('is-spStay');
			}
		}
	});

	$(window).scroll(function() {
		let scrollHeight = $(document).height();
		let scrollPosition = $(window).height() + $(window).scrollTop();
		let footHeight = 220;
		// if(PCWidth){
		// 	()=>{footHeight = 220}
		// }else {
		// 	let footHeight = 220;
		// }
		if ( scrollHeight - scrollPosition  <= footHeight ) { // footerまで行った時
			if(PCWidth){ // pcサイズの時
				$(".pageTop").css({
				  "position":"absolute",
				  "bottom": '220px'
				});
			}else { // spサイズの時
				// $(".pageTop").css({
				// 	"position":"absolute",
				// 	"bottom": '284px'
				// });
				pageTop.addClass('is-spFooter');
			}
		} else { // scroll中
		  $(".pageTop").css({
			  "position":"fixed",
			  "bottom": "44px"
			});
			pageTop.removeClass('is-spFooter');
		}
	  });

	// var setBoxId = '.pageTop';
	// var scrollStart = null;
	// var scrollEnd = null;
	// var floatBoxTop = 240;
	// $(document).ready(function() {
	// 	scrollStart = $(setBoxId).offset().top;
	// 	scrollEnd    = $(document).height() - 1000;
	// })
	// $(window).scroll(scroll)

	// function scroll(){
    //    var scrollTop = $(document).scrollTop() + floatBoxTop;
    //    scrollTop = Math.min(scrollEnd, Math.max(scrollTop, scrollStart))
    //    if(scrollTop === scrollStart ||  scrollEnd === scrollTop) {
    //        $(setBoxId).css({
    //            position:'absolute',
    //            bottom: floatBoxTop
    //        });
    //    } else {
    //        $(setBoxId).css({
    //           position: 'fixed',
    //        });
    //    }
    // }

	// const stopEle = document.querySelector('.america--bana');
	// const StopP = stopEle.getBoundingClientRect().top;
	// const dh = document.innerHeight;
	// const bh = body.getBoundingClientRect().height;
	// console.log(bh);
	// console.log(dh);

	// window.addEventListener('scroll', ()=> {
	// 	let sc = window.scrollTop;
	// 	console.log(sc);

	// 	// if()
	// });

	$('.guest--btn').colorbox({
		fixed: true,
		width: '80%',
		maxWidth: '950px',
		height: '60%',
	});

	// $('.live--box').colorbox({
	// 	inline: true,
	// 	fixed: true,
	// 	width: '80%',
	// 	maxWidth: '950px',
	// 	height: '90%',
	// });
	//modal
 if($(".live--box").length){
    $(".live--box").colorbox({
				inline: true,
      // iframe:true,
      width:"90%",
      height:"90%",
      opacity: 0.7,
      // transition:"fade",
      fixed: true,
      // onOpen: function() {
      //   var ycoord = $(window).scrollTop();
      //   $('#colorbox').data('ycoord',ycoord);
      //   ycoord = ycoord * -1;
      //   $('body').css('position','fixed').css('left','0px').css('right','0px').css('top',ycoord + 'px');
      // },
      // onClosed: function() {
      //   $('body').css('position','').css('left','auto').css('right','auto').css('top','auto');
      //   $(window).scrollTop($('#colorbox').data('ycoord'));
      // }
    });
  }
})(jQuery);

var userAgent = window.navigator.userAgent.toLowerCase();
if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1 ||userAgent.indexOf('edge') != -1) {
	// const mv = document.querySelector('.mv');
	// mv.getElementsByClassName.background = 'background: url(../img/index/banpaku_mv_bg.jpg) center center /cover no-repeat;';
}else {
	setTimeout(() => document.body.classList.add('render'), 60);
	const navigate = (linkEl) => {
		document.body.classList.remove('render');
		document.body.addEventListener('transitionend', () => window.location = linkEl.href);
	};
	document.addEventListener('keydown', (ev) => {
		const keyCode = ev.keyCode || ev.which;
		let linkEl;
		return false;
		navigate(linkEl);
	});
	imagesLoaded('.glitch__img', { background: true }, () => {
		document.body.classList.remove('loading');
		document.body.classList.add('imgloaded');
	});
}
